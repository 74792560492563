const Foxbit = (props) => {
  const updateInterval = props.updateInterval || 5000;
  const apiUri = "https://watcher.foxbit.com.br/api/Ticker/";

  const construct = () => {
    handleUpdate();
    setInterval(handleUpdate, updateInterval);
  };

  const handleUpdate = () => {
    getData().then(parseResponse);
  };

  const updateProduct = (currency) => {
    let price = currency.last;
    if (!price && currency.buyPrice && currency.sellPrice) {
      price = (currency.buyPrice + currency.sellPrice) / 2;
    }

    props.callbackUpdate(currency.currency, {
      price,
      code: currency.currency.replace("BRLX", ""),
      variation: currency.lastVariation,
      volume: currency.vol,
      icon: "",
    });
  };

  const parseResponse = (response) => {
    (response || []).forEach((currency) => {
      if (currency.exchange === "Foxbit") {
        // const prod = props.productList.find(
        // 	item => item.key === currency.currency
        // );

        if (currency.currency.indexOf("BRLX") > -1 && currency.price !== 0) {
          updateProduct(currency);
        }
      }
    });
  };

  const getData = () => {
    return fetch(apiUri)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          console.error(`Request error code: ${response.status}`);
        }
      })
      .catch(function (e) {
        console.error(`Request error: ${e.message}`);
      });
  };

  construct();
};

export default Foxbit;
