import React, { Component } from "react";

class Currency extends Component {
  formatNumber = (n) => {
    let digits = 2;
    try {
      digits = Math.max(
        2,
        `${n}`.split(".")[1].replace(/[1-9]/g, "").length + 2
      );
    } catch (e) {}
    return (typeof n === "string" ? parseFloat(n) : n).toLocaleString("pt-BR", {
      minimumFractionDigits: digits,
      maximumFractionDigits: digits,
    });
  };

  shouldComponentUpdate(nextProps) {
    return this.props.price !== nextProps.price;
  }

  render() {
    let price = this.props.price;
    if (+price <= 0 || isNaN(price)) {
      price = "-";
    } else {
      price = this.formatNumber(price);
    }

    let variation = this.props.variation;
    if (isNaN(variation)) {
      variation = "-";
    } else {
      variation = this.formatNumber(variation);
    }

    const style = {};

    if (this.props.image) {
      style.backgroundImage = `url("${this.props.image}")`;
    }

    return (
      <div
        className={`fb-currency ${this.props.extraClass} fb-currency-${this.props.code}`}
        style={this.props.style}
      >
        <span className="fb-code">{this.props.code}</span>
        <span className="fb-currency-price">
          <span className="symbol">{this.props.currency_symbol || "R$"}</span>{" "}
          {price}
        </span>
        <span className={`fb-currency-variation`}>
          <span
            className="value"
            style={
              this.props.variation === 0
                ? {}
                : {
                    color:
                      +this.props.variation > 0
                        ? this.props.styles.green
                        : this.props.styles.red,
                  }
            }
          >
            {variation}%
          </span>
        </span>
      </div>
    );
  }
}

Currency.defaultProps = {
  name: "",
  currency_symbol: "",
  image: "",
  price: 0,
  variation: 0,
  extraClass: "",
};

export default Currency;
