
const Coinbase = (props) => {
    const products = [];
    let socket;

    const calcPercent = (price, open) => {
        try {
          const value = ((price / open) - 1) * 100;
          if (isNaN(value)) {
              return 0;
          }
          return value.toFixed(2);
        } catch (error) {
          return 0;
        }
      };

    const createSocket = () => {
        socket = new WebSocket('wss://ws-feed.pro.coinbase.com');

        socket.addEventListener('open', onOpen);
        socket.addEventListener('message', onMessage);
    };

    const onOpen = () => {
        const data = {
          type: 'subscribe',
          product_ids: [...products],
          channels: ['ticker'],
        };

        socket.send(JSON.stringify(data));
      };

      const onMessage = (event) => {
        const data = JSON.parse(event.data);

        if (data.type !== 'ticker') {
          return;
        }

        if (data.product_id) {
            props.callbackUpdate(
                data.product_id,
                {
                    price: data.price,
                    variation: calcPercent(data.price, data.open_24h),
                    volume: data.volume_24h,
                }
            )
        }
      };


    if (props.productList) {
        for (let i = 0, l = props.productList.length; i < l; i = i + 1) {
            if (props.productList[i].key) {
                products.push(props.productList[i].key);
            }
        }
    }

    createSocket();
};

export default Coinbase;