/* global arguments */
import React, { Component } from "react";
import Currency from "./components/Currency";
import Slider from "./components/Slider";
import Services from "./services";
import "./App.scss";

const debounce = (func, wait, immediate) => {
  var timeout;
  return () => {
    const context = this,
      args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

const getWidth = function () {
  const w = window,
    d = document,
    e = d.documentElement,
    g = d.getElementsByTagName("body")[0];

  return w.innerWidth || e.clientWidth || g.clientWidth;
};
class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      animate: true,
      styles: App.defaultProps.styles,
      currencies: {},
    };

    this.updateCurrencyProps = this.updateCurrencyProps.bind(this);

    window.addEventListener(
      "resize",
      debounce(
        () => {
          this.setAnimate();
        },
        200,
        false
      ),
      false
    );

    window.addEventListener(
      "orientationchange",
      () => this.setAnimate(),
      false
    );
  }
  registerProductsListening(service, productList) {
    try {
      if ({}.hasOwnProperty.call(Services, service)) {
        Services[service]({
          callbackUpdate: this.updateCurrencyProps,
          productList,
        });
      }
    } catch (e) {
      console.error(e);
    }
  }

  updateCurrencyProps(currencyKey, props) {
    this.setState((prevState) => {
      const currencies = Object.assign(prevState.currencies, {
        [currencyKey]: Object.assign(
          prevState.currencies?.[currencyKey] || {},
          props
        ),
      });

      return {
        currencies,
      };
    });
  }

  setAnimate = () => {
    this.setState({
      animate: getWidth() < this.props.tickerAnimateBelowSize,
    });
  };

  componentDidMount() {
    const servicesList = { Foxbit: [] };

    for (const service in servicesList) {
      if (servicesList.hasOwnProperty(service)) {
        this.registerProductsListening(service, servicesList[service]);
      }
    }
    // this.setAnimate();
  }

  render() {
    let content = (
      <div className={"fb-bar " + (this.state.animate ? "animate" : "")}>
        <Slider
          animated={this.state.animate}
          currencies={this.state.currencies}
          styles={this.state.styles}
        />
        <style>{`
                .fb-currency:after{background-color:${this.state.styles.border_color}}
                .fb-currency-price{color:${this.state.styles.price_color}}
                `}</style>
        <span className="see-all">Ver todas</span>
      </div>
    );

    if (this.props.url && this.props.url !== "#") {
      return (
        <a href={this.props.url} target={this.props.url_target}>
          {content}
        </a>
      );
    }

    return content;
  }
}

App.defaultProps = {
  currencies: {},
  url_target: "_blank",
  url: "/crypto-price/",
  tickerAnimateBelowSize: 0,
  styles: {
    red: "#B6180E",
    green: "#2C7A5E",
    border_color: "#FFFFFF",
    price_color: "#121212",
    height: 168,
    fontSize: 16,
    main: {
      backgroundColor: "#FFF",
      color: "#000",
    },
    list: {
      backgroundColor: "#FFF",
      color: "#000",
    },
  },
};

export default App;
