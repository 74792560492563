import React, { Component } from "react";
import Currency from "./Currency";

class Slider extends Component {
  render() {
    const _currencies = this.props.currencies;
    const currencies = Object.keys(_currencies).map((c) => {
      const currency = _currencies[c];

      return (
        <Currency
          {...currency}
          style={this.props.styles.list}
          key={c}
          styles={this.props.styles}
        />
      );
    });

    return (
      <div className="fb-currencies">
        <div className="fb-currencies-list">{currencies}</div>
        {this.props.animated ? (
          <div className="fb-currencies-list">{currencies}</div>
        ) : (
          false
        )}
        {this.props.animated ? (
          <div className="fb-currencies-list">{currencies}</div>
        ) : (
          false
        )}
      </div>
    );
  }
}

Slider.defaultProps = {
  currencies: [],
};

export default Slider;
